$(document).ready(function ($) {
    //if no depth3, do not display submenu in cols
/*     $(".mega-menu").each(function(){
       if ($(this).find('.menu-list').length) {
            //nothing now
       } else {
            $(this).addClass('no-flex');
       }
    })*/

    //menu dropdown show/hide
    $(document).on('click','.menu-item-has-children > a',function(){
          let $this = $(this).parent(".menu-item-has-children");
          $(".menu-item-has-children").not($this).removeClass('is-open');
          $this.toggleClass('is-open');
    });

    $(document).mouseup(function(e) {
        //hide dropdown if clicked outside menu
        var container = $(".menu");
        if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0)  { // ... nor a descendant of the container
            //console.log(e.target);
            $(".menu-item-has-children").removeClass('is-open');
        }
    });

    //toggle mobile class on body
    $(".mobile-menu-trigger").click(function(e){
        $('nav').toggle();
        $('body').toggleClass('is-mobile-menu-open');
    })

    document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');

    $(".mega-menu").find("li").each(function(){
         $(this).on('mouseenter', function(){
             let firstParentW = $(this).parent('ul').first().width()+5;
             if ($(this).parent().hasClass("menu-depth-3")) {
                 //depth-n
                 if ($(this).hasClass("menu-item-has-gran-children")) {
                    $(this).find("ul").first().css('left',firstParentW +'px').addClass("show-hover");
                 }
             } else {
                 if ($(this).hasClass("menu-item-has-gran-children")) {
                    if ($(this).parent().hasClass("menu-depth-n")) {
                        //depth-n2
                        $(this).find("ul").first().css('left',firstParentW +'px').addClass("show-hover");
                    } else {
                        //depth3
                        let menuW = $(this).parents(".mega-menu").width()-15;
                        $(this).find("ul").first().css('left',menuW +'px').addClass("show-hover");
                    }
                 } else {
                     if (!($(this).parent('ul').hasClass("menu-depth-n") || $(this).parent('ul').hasClass("menu-depth-3"))) {
                        $(".show-hover").removeClass("show-hover");
                     }
                 }
             }
         })
    })
});