function check_empty(id) {
    if (jQuery("#" + id).val() == '') {
        jQuery("#" + id).addClass('fieldErr');
            jQuery('#alert-danger').removeClass('hide').addClass('in');
            jQuery('#err').text('Please insert valid data!');
            return false;
    } else {
        jQuery("#" + id).removeClass('fieldErr');
        return true;
    }
}

/**
 * Validate CNP
 * @param   {string}  value CNP
 * @returns {boolean} Valid or not
 */
function validateCNP(value) {
	var re = /^\d{1}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d| 5[0-2]|99)\d{4}$/,
		bigSum = 0,
		rest = 0,
		ctrlDigit = 0,
		control = '279146358279',
		i = 0;
	if (re.test(value)) {
		for (i = 0; i < 12; i++) {
			bigSum += value[i] * control[i];
		}
		ctrlDigit = bigSum % 11;
		if (ctrlDigit === 10) {
			ctrlDigit = 1;
		}

		if (ctrlDigit !== parseInt(value[12], 10)) {
			return false;
		} else {
			return true;
		}
	}
	return false;
};

/**
 * Validate CIF
 * @param   {string}  value CIF
 * @returns {boolean} Valid or not
 */

function validateCIF(cui) {
    // if string remove first 2 chars and spaces
    var cif = cui;
    if(isNaN(parseFloat(cui)) || !isFinite(cui)){
        cif = cif.toUpperCase();
        if(cif.indexOf('RO') === 0){
            cif = cif.replace('RO', '');
        } else {
            return false;
        }
        cif = parseInt(cif.trim());
    } else {
       cif = parseInt(cif);
    }
    // invalid if > 10 or < 6
    if(cif.toString().length > 10 || cif.toString().length < 6){
       return false;
    }
    // control nr
    var v = 753217532;

    // extract control digit
    var c1 = parseInt(cif % 10);
    cif = parseInt(cif / 10);

    // execute digit operations
    var t = 0;
    while (cif > 0) {
      t += (cif % 10) * (v % 10);
      cif = parseInt(cif / 10);
      v = parseInt(v / 10);
    }

    // multiply by 10 and get % by 11
    var c2 = parseInt((t * 10) % 11);
    // if modulo % 11 este 10, then control digit  = 0
    if(c2 == 10){
       c2 = 0;
    }

    return c1 === c2;
}

function validateForm(form) {

     var valid = false;
     jQuery('.fieldErr').removeClass('fieldErr');

     jQuery("input, select, textarea").each(function(){
        if (jQuery(this).prop('required')){
            if (check_empty(jQuery(this).attr('id'))) {
                valid = true;
            } else {
                valid = false;
            }
        }
     })

     if (valid == true) {
        //validate cnp / cui
         var customerUid = jQuery('#id_customer_uid').val();
         var customerType = jQuery("#id_customer_type").val();
         //1 - Fizic
         //2 - Juridic
         var validCustomerUid =  (customerType == 1) ? validateCNP(customerUid) : validateCIF(customerUid);
         var errText = (customerType == 1)  ? 'CNP' : 'CUI';
         if ( validCustomerUid ) {
            valid = true;
         } else {
             jQuery("#id_customer_uid").addClass("fieldErr");
             jQuery(".ksw-form").before('<div class="alert-danger"><p>Invalid ' + errText + '!</p></div>');
             valid = false;
         }
     }

     return valid;
}

//calc 2 colors-bkg in dashboard
function calc_bkg_rule() {
    var sideOffset = jQuery(".dashboard .side-content").offset();
    var wide = jQuery('body').width();
    if (sideOffset) {
        var percent = (parseInt(sideOffset.left)*100)/parseInt(wide);

        console.log(percent);
        console.log(wide);

        var bkg_rule = 'background: linear-gradient(to left, #f4f7fc 0, #f7fcfc 50%, #f7fcfc ' + parseInt(100-parseInt(percent)+2) + '%,#fff 40%, #fff 40%)';
        jQuery(".dashboard .page-content").attr('style',bkg_rule);
    }
}


jQuery(document).ready(function(){

    //add sticky class to #masthead
    jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() >= 450) {
            jQuery("#masthead").addClass('opac');
        } else {
            jQuery("#masthead").removeClass('opac');
        }
    });

    //show / hide search modal
    jQuery("#search-modal, #search-modal-mobile").click(function(e){
        if (jQuery("#mega-nav-search").hasClass('active')) {
             jQuery("#mega-nav-search").removeClass('active');
             jQuery(this).find("span").removeClass('lnr-cross').addClass('lnr-magnifier');
        } else {
            jQuery("#mega-nav-search").addClass('active');
            jQuery(this).find("span").removeClass('lnr-magnifier').addClass('lnr-cross');
        }
    })

    jQuery(".sub-menu input").each(function(){
        jQuery(this).change(function(){
            jQuery(".sub-menu .sub-content").removeClass('show');
            if (jQuery(this).is(':checked')){
                jQuery(".content-container").css('display', 'block');
                jQuery("#c"+jQuery(this).attr('data-tab')).addClass('show');
            }
        })
    })

    var myLazyLoad = new LazyLoad({
        elements_selector: ".lazy"
    });

/* Back-to-top */
    var offset = 300,
        //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
        offset_opacity = 1200,
        //duration of the top scrolling animation (in ms)
        scroll_top_duration = 700,
        //grab the "back to top" link
        $back_to_top = jQuery('.ksw-top');

    //hide or show the "back to top" link
    jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() > offset) {
            $back_to_top.addClass('ksw-is-visible');
            //fix lazy load on stupid laptops
            myLazyLoad.loadAll();

        } else {
            $back_to_top.removeClass('ksw-is-visible ksw-fade-out');
        }
        if (jQuery(this).scrollTop() > offset_opacity) {
            $back_to_top.addClass('ksw-fade-out');
        }
    });

    /* smooth scroll to top */
    $back_to_top.on('click', function (event) {
        event.preventDefault();
        if (jQuery(this).hasClass('ksw-ask')) {
            jQuery('#slide-in-box-contact').addClass('active');
        } else {
            jQuery('body,html').animate({
                    scrollTop: 0,
                }, scroll_top_duration
            );
        }
    });

    //calc 2 colors-bkg in dashboard
    if (jQuery(".dashboard")) {
        calc_bkg_rule();
        jQuery(window).resize(function(){
            calc_bkg_rule();
        });
    }

    var newsImg = jQuery("#news-bkg-img").find('img').attr('src');
    if (newsImg) {
        jQuery("#news-section").attr('flazydata-bg', 'url("' + newsImg + '")');
    }

    //fix for lang switcher, active state
    jQuery(".lang.active").addClass("lang-active");

    //validate signup form before submit
    jQuery("#signup_form").on("submit", function(e){
       return validateForm();
    })

   jQuery(document).on("keyup", ".fieldErr", function(){
        jQuery(this).removeClass('fieldErr');
        jQuery(".alert-danger").remove();
    })

})