jQuery(document).ready(function(){
    // expand/collapse mobile sidebar menu
    jQuery(".expand-menu").click(function(e){
        e.preventDefault();
        jQuery(this).parents(".sidebar-inner").toggleClass("in");
        jQuery(this).find('.lnr').toggleClass("lnr-arrow-right").toggleClass('lnr-arrow-left');
        jQuery('body').toggleClass("with-overlay");
    });

    //display prev index on watermeter select
    jQuery("#id_water_meter").on('change', function(){
        var selected_val = jQuery(this).val();
        jQuery(".index-box").addClass('hide');
        if (selected_val != '') {
           if (jQuery('*[data-watermeter="' + selected_val +'"]').length > 0 ){
                jQuery('*[data-watermeter="' + selected_val +'"]').removeClass('hide');
           } else {
            jQuery(".index-default").removeClass('hide');
           }
        } else {
            jQuery(".index-default").removeClass('hide');
        }
    })
})